import { useState, useRef, useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import { CustomerProfile, GearMapBucket, ModalProps, ProfileGear, QueryParams } from 'types';
import { GearPageProps } from './GearPage';
import { getGearList } from 'api';
import { composeServerErrorForSnackbar, formatFiltersToQueryString } from 'utils/formatters';
import {
  checkIfFullyAuthorized,
  goToComment,
  handleFavorite,
  isParticularGearOwner,
  toAuthAndBack,
} from 'utils/services';
import { Snackbar, SnackbarProps } from 'components/Snackbar';
import { GearSortDropdown } from 'components/GearSortDropdown';
import { Modal } from 'components/Modal';
import { GearFilterPanel } from 'components/GearFilterPanel';
import { LazyLoader } from 'components/LazyLoader';
import { NextIcon } from 'components/NextImage';
import { GearsMap, MapFilters } from 'components/GearsMap';
import { GearCard } from 'components/GearCard';
import { ShareModal } from 'containers/MyGear/ShareModal';

import cx from 'classnames';
import styles from './GearPage.module.scss';
import { Activities } from '../../components/Activities';
import { useAppDispatch } from '../../store';
import { NextLink } from '../../components/NextLink';

type SortFields = 'posted_at' | 'price';
type SortOrders = 'asc' | 'desc';

export type SortData = {
  sort: SortFields;
  order: SortOrders;
};

export const LIST_LIMIT = 12;

const initialSorting: SortData = {
  sort: 'posted_at',
  order: 'desc',
};

export type ViewMode = 'grid' | 'map';

export type ViewModeData = {
  mode: ViewMode;
  changeCount: Number;
};

export type GearBlockProps = Omit<GearPageProps, 'menuData'> & {
  showResultCount?: boolean;
  isHome?: boolean;
};

export const GearBlock = ({
  user,
  initialGear,
  activities,
  filters,
  appliedFilters,
  isHome
}: GearBlockProps) => {
  const router = useRouter();
  const [snackbarMessage, setSnackbarMessage] = useState<SnackbarProps | null>(null);
  const [currentGear, setCurrentGear] = useState<ProfileGear[]>(initialGear.hits || []);
  const [currentBuckets, setCurrentBuckets] = useState<GearMapBucket[]>(initialGear.buckets || []);
  const [totalPages, setTotalPages] = useState(Math.ceil(initialGear.total / LIST_LIMIT));
  const [currentPage, setCurrentPage] = useState(parseInt(appliedFilters.page) || 1);
  const [wholeItemsCount, setWholeItemsCount] = useState(initialGear.total);
  const [filterQueryParams, setFilterQueryParams] = useState<QueryParams>(appliedFilters);
  const [prevQuery, setPrevQuery] = useState<QueryParams>(appliedFilters);
  const [mapFilterQueryParams, setMapFilterQueryParams] = useState<QueryParams>({});
  const [sorting, setSorting] = useState<SortData>(initialSorting);
  const [prevSorting, setPrevSorting] = useState<SortData>(initialSorting);
  const [readyToLoad, setReadyToLoad] = useState(true);
  const [viewModeData, setViewModeData] = useState<ViewModeData>({ mode: appliedFilters.type==='rent' ? 'map' : 'grid', changeCount: 0 });
  const isDelayedLoadingSet = useRef(false);
  const [modalProps, setModalProps] = useState<ModalProps | null>(null);
  const isFullyAuthorized = checkIfFullyAuthorized(user);
  const mounted = useRef(false); // component mounted

  useEffect(() => {
    if (!mounted.current && currentGear.length > 0) {
      mounted.current = true;
      return;
    }
    if(JSON.stringify(prevQuery)===JSON.stringify(filterQueryParams) && JSON.stringify(prevSorting)===JSON.stringify(sorting))
      return;

    setCurrentPage(1);
    loadGear(true).then();
    setPrevQuery({...filterQueryParams})
    setPrevSorting({...sorting})
    history.replaceState(null, '', `${document.location.pathname}${formatFiltersToQueryString({...filterQueryParams, page: 1})}`);
  }, [filterQueryParams, sorting]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    history.replaceState(null, '', `${document.location.pathname}${formatFiltersToQueryString({...filterQueryParams, page: currentPage})}`);
  }, [currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFilterQueryParams(appliedFilters);
  }, [router.asPath]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setViewModeData({ mode: appliedFilters.type==='rent' ? 'map' : 'grid', changeCount: 0 });
  }, [appliedFilters.type]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (viewModeData.changeCount > 0 || viewModeData.mode === 'map') {
      if (viewModeData.mode === 'grid') {
        setCurrentGear([]);
        setMapFilterQueryParams({});
        loadGear(true).then();
      } else {
        setCurrentGear([]);
        setCurrentBuckets([]);
      }
    }
  }, [viewModeData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (viewModeData.mode === 'map') {
      loadGear(true).then();
    }
  }, [mapFilterQueryParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadGear = async (force?: boolean) => {
    if (!readyToLoad && !isDelayedLoadingSet.current) {
      isDelayedLoadingSet.current = true;
      force && setTimeout(() => loadGear(force), 300);
      return;
    }
    if (readyToLoad && isDelayedLoadingSet.current) {
      isDelayedLoadingSet.current = false;
    }

    setReadyToLoad(false);
    const mapfilters = viewModeData.mode === 'map' ? mapFilterQueryParams : {};
    const response = await getGearList({
      ...filterQueryParams,
      ...mapfilters,
      ...sorting,
      page: force ? 1 : currentPage + 1,
      limit: LIST_LIMIT,
    });

    if (response.ok && response.hits) {
      setCurrentPage(force ? 1 : currentPage + 1);
      setTotalPages(Math.ceil(response.total / LIST_LIMIT));
      setWholeItemsCount(response.total);
      setCurrentGear((previous) => {
        if (force) {
          return response.hits;
        }
        return [...previous, ...response.hits];
      });
      setCurrentBuckets(response.buckets);
    } else {
      setSnackbarMessage(composeServerErrorForSnackbar(response));
    }
    setReadyToLoad(true);
  };

  const handleSelectViewMode = (mode: ViewMode) => {
    setViewModeData({
      mode,
      changeCount: viewModeData.changeCount + 1,
    });
  };

  const handleChangeMap = (mapFilters: MapFilters) => {
    setMapFilterQueryParams({
      geo_clustering: mapFilters,
    });
  };

  const handleShareModalOpen = useCallback((slug: string, owner: Partial<CustomerProfile>, isSold: boolean) => {
    setModalProps({
      child: (
        <ShareModal
          dividerText="or"
          isGearOwner={isParticularGearOwner(owner, user)}
          isSold={isSold}
          slug={slug}
          shareToFollowersBtn
          user={user}
        />
      ),
      title: 'Share this gear',
      onCoverClick: () => {
        setModalProps(null);
      },
      withCrossIcon: true,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const redirectToAuth = useCallback(() => toAuthAndBack(window.location.pathname, router, user), [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleFavorite = useCallback(
    (slug: string, isFavorite: boolean) => handleFavorite(slug, isFavorite, setSnackbarMessage),
    [],
  );

  const onComment = useCallback(goToComment, []);

  return (
    <div>
      <div className={cx(styles.sellTypeContainer, styles.forMob)}>
        <div className={styles.separator}/>
        <div className={cx(styles.sellTypeCell, appliedFilters.type==='sell' && styles.active)}>
          <NextLink href={`/gear?type=sell`}>
            <p className={cx(styles.submenuItem)}>BUY</p>
          </NextLink>
        </div>
        <div className={cx(styles.sellTypeCell, appliedFilters.type==='rent' && styles.active)}>
          <NextLink href={`/gear?type=rent`}>
            <p className={cx(styles.submenuItem)}>RENT</p>
          </NextLink>
        </div>
      </div>
      {isHome && (
        <>
          <div className={cx(styles.promoBox, styles.forNotMob)}>
            <video className={styles.promo} playsInline autoPlay muted loop>
              <source src="/promo.mp4" type="video/mp4" />
            </video>
          </div>
          <div className={cx(styles.promoBox, styles.forMob)} onClick={() => window.location.assign('https://onelink.to/sendy')}>
            <video className={styles.promo} playsInline autoPlay muted loop>
              <source src="/promoMob.mp4" type="video/mp4" />
            </video>
          </div>
        </>
      )}

      <Activities activities={activities} className={styles.gearActivities} />

      {!!filterQueryParams.q && (
        <div className={styles.searchResultFor}>
          <p className={styles.title}>Search results for:</p>
          <p className={styles.query}>{filterQueryParams.q}</p>
        </div>
      )}
      <div className={styles.container}>
        <GearFilterPanel filters={filters} setFilterQueryParams={setFilterQueryParams} presetFilters={appliedFilters} isHome={isHome} />
        <div className={styles.listWrapper}>
          {isHome || (
            <div className={styles.listHeader}>
              <div className={styles.listHeaderControls}>
                <GearSortDropdown sort={sorting.sort} order={sorting.order} onChange={setSorting} />
                {appliedFilters.type==='rent' && (
                  <div className={styles.listHeaderViewMode}>
                    <div
                      className={cx(styles.listHeaderViewModeItem, {
                        [styles.activeMode]: viewModeData.mode === 'map',
                      })}
                      onClick={() => handleSelectViewMode('map')}
                    >
                      <NextIcon
                        src={`/icons/maps/MapTrifold${viewModeData.mode === 'map' ? 'Dark' : 'Light'}.svg`}
                        customClass={styles.listHeaderViewModeItemIcon}
                      />
                      <span className={styles.listHeaderViewModeItemTitle}>Map View</span>
                    </div>
                    <div
                      className={cx(styles.listHeaderViewModeItem, {
                        [styles.activeMode]: viewModeData.mode === 'grid',
                      })}
                      onClick={() => handleSelectViewMode('grid')}
                    >
                      <NextIcon
                        src={`/icons/design/GridFour${viewModeData.mode === 'grid' ? 'Dark' : 'Light'}.svg`}
                        customClass={styles.listHeaderViewModeItemIcon}
                      />
                      <span className={styles.listHeaderViewModeItemTitle}>Grid View</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {viewModeData.mode === 'grid' ? (
            <div className={styles.list}>
              {currentGear.map((gear) => (
                <GearCard
                  key={gear.id}
                  {...gear}
                  toggleFavorite={isFullyAuthorized ? toggleFavorite : redirectToAuth}
                  onShare={handleShareModalOpen}
                  onComment={isFullyAuthorized ? onComment : redirectToAuth}
                />
              ))}

              {totalPages > 1 && totalPages !== currentPage && (
                <LazyLoader isActive={readyToLoad} callback={loadGear} />
              )}
            </div>
          ) : (
            <GearsMap
              gears={currentGear}
              buckets={currentBuckets}
              filters={{ ...filterQueryParams }}
              onChange={handleChangeMap}
              toggleFavorite={isFullyAuthorized ? toggleFavorite : redirectToAuth}
              onShare={handleShareModalOpen}
              onComment={isFullyAuthorized ? onComment : redirectToAuth}
            />
          )}
        </div>
      </div>

      {snackbarMessage && <Snackbar {...snackbarMessage} onClose={() => setSnackbarMessage(null)} />}
      {modalProps && <Modal {...modalProps} />}
    </div>
  );
};
