import { useEffect, useRef, useState } from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';
import { ProfileGear, GearMapBucket, QueryParams, ModalProps, CustomerProfile } from 'types';
import { GOOGLE_MAPS_API_KEY } from 'consts';
import Map, { MapData, MIN_ZOOM, MAX_ZOOM } from './Map';
import { GeoHashList } from './GeoHashList';

import styles from './GearsMap.module.scss';
import { getGearList } from '../../api';
import { composeServerErrorForSnackbar } from '../../utils/formatters';
import { LIST_LIMIT } from '../../containers/Gear';
import { GearCard } from '../GearCard';
import { LazyLoader } from '../LazyLoader';
import { SnackbarProps } from '../Snackbar';
import { checkIfFullyAuthorized } from '../../utils/services';
import { SortData, ViewModeData } from '../../containers/Gear/GearBlock';

const MAX_GEOHASH_PRECISION = 12;

const zoomLevelToGeohashPrecision = (zoom: number) => {
  const zoomLevelsCount = MAX_ZOOM - MIN_ZOOM;
  const currentZoomBaseZero = zoom - MIN_ZOOM;

  if (currentZoomBaseZero === zoomLevelsCount) {
    return MAX_GEOHASH_PRECISION;
  } else if (currentZoomBaseZero <= 0) {
    return 1;
  }

  return Math.round((MAX_GEOHASH_PRECISION / zoomLevelsCount) * currentZoomBaseZero);
};

export type MapFilters = {
  precision: number;
  bounds: {
    tr: {
      // Top right
      lat: number;
      lng: number;
    };
    bl: {
      // Bottom left
      lat: number;
      lng: number;
    };
  };
};

export type GearsMapProps = {
  gears: ProfileGear[];
  buckets: GearMapBucket[];
  filters: QueryParams;
  onChange: (mapFilters: MapFilters) => void;
  toggleFavorite: (slug: string, isFavorite: boolean) => Promise<boolean>;
  onComment: (slug: string) => void;
  onShare: (slug: string, owner: Partial<CustomerProfile>, isSold: boolean) => void;
};

export const GearsMap = ({ gears, buckets, filters, onChange, toggleFavorite, onShare, onComment }: GearsMapProps) => {
  const [geoHash, setGeoHash] = useState<string | null>(null);
  const mapRef = useRef(null); // Додаємо референс до карти

  const [currentGear, setCurrentGear] = useState<ProfileGear[]>([]);
  const [totalPages, setTotalPages] = useState(Math.ceil(LIST_LIMIT));
  const [currentPage, setCurrentPage] = useState(parseInt(filters.page) || 1);
  const [mapFilterQueryParams, setMapFilterQueryParams] = useState<QueryParams>({});
  const [readyToLoad, setReadyToLoad] = useState(true);
  const isDelayedLoadingSet = useRef(false);

  useEffect(() => {
    loadGear(true).then();
  }, [mapFilterQueryParams]); // eslint-disable-line react-hooks/exhaustive-deps


  const loadGear = async (force?: boolean) => {
    if (!readyToLoad && !isDelayedLoadingSet.current) {
      isDelayedLoadingSet.current = true;
      force && setTimeout(() => loadGear(force), 300);
      return;
    }
    if (readyToLoad && isDelayedLoadingSet.current) {
      isDelayedLoadingSet.current = false;
    }

    setReadyToLoad(false);
    const response = await getGearList({
      ...filters,
      ...mapFilterQueryParams,
      page: force ? 1 : currentPage + 1,
      limit: LIST_LIMIT,
    });

    if (response.ok && response.hits) {
      setCurrentPage(force ? 1 : currentPage + 1);
      setTotalPages(Math.ceil(response.total / LIST_LIMIT));
      setCurrentGear((previous) => {
        if (force) {
          return response.hits;
        }
        return [...previous, ...response.hits];
      });
    }
    setReadyToLoad(true);
  };

  const handleChange = (mapData: MapData) => {
    onChange({
      precision: zoomLevelToGeohashPrecision(mapData.zoom),
      bounds: mapData.bounds,
    });
    setMapFilterQueryParams({geo_bounds: mapData.bounds})
  };

  const handleBucketSelected = (geoHash: string | null) => {
    setGeoHash(geoHash);
  };

  const handleMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          if (mapRef.current) {
            mapRef.current.panTo({ lat: latitude, lng: longitude }); // Зміщення центру карти
            mapRef.current.setZoom(MAX_ZOOM); // Встановлення масштабу карти
          }
        },
        (error) => {
          console.error("Error getting location: ", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };


  return (
    <>
      <div className={styles.container} suppressHydrationWarning={true}>
        <button onClick={handleMyLocation} className={styles.myLocationButton}>
          My Location
        </button>
        <Wrapper apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY || GOOGLE_MAPS_API_KEY}>
          <Map ref={mapRef} gears={gears} buckets={buckets} onChanged={handleChange}
               onBucketSelected={handleBucketSelected} />
        </Wrapper>
        {geoHash && <GeoHashList geoHash={geoHash} filters={filters} />}
      </div>
      <div style={{ height: 24 }} />
      <div className={styles.list}>
        {currentGear.map((gear) => (
          <GearCard
            key={gear.id}
            {...gear}
            toggleFavorite={toggleFavorite}
            onShare={onShare}
            onComment={onComment}
          />
        ))}

        {totalPages > 1 && totalPages !== currentPage && (
          <LazyLoader isActive={readyToLoad} callback={() => loadGear(false)} />
        )}
      </div>
    </>
  )
    ;
};
